export default {
  methods: {
    camelToSnake(text) {
      return text.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    },
    snakeToCamel(text) {
      return text.replace(/_[a-z]/g, letter => `${letter.toUpperCase().replace('_', '')}`)
    },
  },
}
