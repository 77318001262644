<template>
  <div
    v-if="content"
    :style="{
      pointerEvents: isStoring ? 'none' : 'auto',
    }"
  >
    <b-breadcrumb
      class="mb-1"
      :items="[
        {
          text: 'Planejador',
          active: true, // Não tem link
        },
        {
          text: 'Conteúdos',
          to: { name: 'cms-planner-contents-list' },
        },
        {
          text: 'Edição',
          active: true,
        },
      ]"
    />
    <b-card>
      <b-card-title> Conteúdo </b-card-title>
      <b-card-body>
        <validation-observer
          ref="rulesContent"
        >
          <b-row>
            <b-col>
              <b-form-group
                label="Título"
                label-for="title"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Formato"
                label-for="template"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-select
                    id="template"
                    v-model="template"
                    :options="plannerContentTemplateOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Texto"
            label-for="text"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-textarea
                id="text"
                v-model="text"
                rows="10"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <span v-if="template === 'dummy_confirm_email'">
              Variáveis: {email}
            </span>

            <span v-if="template === 'dummy_confirm_whatsapp'">
              Variáveis: {cellphone}
            </span>

            <span v-if="template === 'dummy_confirm_cpf'">
              Variáveis: {cpf}
            </span>

            <span v-if="template === 'dummy_confirm_cnpj'">
              Variáveis: {cnpj}
            </span>

            <span v-if="template === 'dummy_new_message'">
              Variáveis: {destinatario}
            </span>

            <span v-if="template === 'dummy_connection_without_answer'">
              Variáveis: {destinatario}
            </span>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group
                label="Dificuldade"
                label-for="difficulty"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-select
                    id="difficulty"
                    v-model="difficulty"
                    :options="plannerActionDifficultiesOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Tipo"
                label-for="type"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-select
                    id="type"
                    v-model="type"
                    :options="userTypeOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Duração (em minutos)"
                label-for="duration"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-spinbutton
                    id="duration"
                    v-model="duration"
                    min="1"
                    wrap
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Ordem (Crescente)"
                label-for="order"
                description="Quando houver múltiplos conteúdos de mesma ordem, será escolhido um deles aleatoriamente."
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-spinbutton
                    id="order"
                    v-model="order"
                    min="1"
                    wrap
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Recorrência"
                label-for="recurrence_in_days"
                description="Esta tarefa pode se repetir a cada X dias. 0 para não se repetir"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-spinbutton
                    id="recurrence_in_days"
                    v-model="recurrenceInDays"
                    min="0"
                    wrap
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Distribuição instantânea"
                label-for="instant_distribution"
                description="Distribui a tarefa instantaneamente para todos os usuários com alguma campanha ativa. Os usuários futuros receberão a tarefa conforme a distribuição normal."
              >
                <b-form-checkbox
                  id="instant_distribution"
                  v-model="instantDistribution"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="">
            <h4>Botões</h4>
            <div class="ml-2 mb-2">
              <span v-if="template === 'dummy_new_message'">
                Variáveis: <br />
                {link_answer_message} - Perfil do usuário com modal de envio de mensagem aberta (Para ser usado no botão Enviar nova mensagem)<br />
                <br />
                Comportamento:<br />
                Confirmar Opção Dummy Task: Encerra a negociação e a tarefa
              </span>

              <span v-if="template === 'dummy_connection_without_answer'">
                Variáveis: <br />
                {link_new_message} - Perfil do usuário com modal de envio de mensagem aberta (Para ser usado no botão Respnder Mensagem)<br />
                <br />
                Comportamento:<br />
                Confirmar Opção Dummy Task: Encerra a tarefa
              </span>
            </div>
            <b-row v-if="buttons.length > 0">
              <b-col>
                <b-form-group label="Link" />
              </b-col>
              <b-col>
                <b-form-group label="Texto" />
              </b-col>
              <b-col>
                <b-form-group label="Tipo" />
              </b-col>
              <b-col>
                <b-form-group label="Destino" />
              </b-col>
            </b-row>
            <b-row
              v-for="(button, index) in buttons"
              :key="index"
              class="mb-1"
            >
              <b-col>
                <b-form-input v-model="button.contentLink" />
              </b-col>
              <b-col>
                <b-form-input v-model="button.text" />
              </b-col>
              <b-col>
                <b-form-select
                  v-model="button.type"
                  :options="plannerContentButtonTypeOptions"
                />
              </b-col>
              <b-col>
                <div class="d-flex justify-content-between align-items-center">
                  <b-form-select
                    v-model="button.target"
                    :options="plannerContentButtonTargetOptions"
                  />
                  <b-button
                    variant="outline-danger"
                    class="ml-1"
                    @click="buttons.splice(index, 1)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-button
            variant="secondary"
            @click="addButton"
          >
            Novo
          </b-button>

          <div class="text-right">
            <b-button
              variant="gradient-primary"
              @click="store()"
            >
              Salvar
              <b-spinner
                v-if="isStoring"
                small
              />
            </b-button>
          </div>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormSpinbutton,
  BRow,
  BCol,
  BBreadcrumb,
  BSpinner,
  BButton,
  BCardTitle,
  BFormSelect,
  BCardBody,
} from 'bootstrap-vue'
import {
  plannerActionDifficultiesOptions,
  plannerContentTemplateOptions,
  plannerContentButtonTargetOptions,
  plannerContentButtonTypeOptions,
  userTypes,
  withEmptyOptionFirst,
} from '@/utils/options'
import PlannerService from '@/services/plannerService'
import convertCase from '@/mixins/convertCase'
import toast from '@/mixins/toast'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

export default {
  name: 'PlannerContentsEdit',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormSpinbutton,
    BRow,
    BCol,
    BBreadcrumb,
    BSpinner,
    BButton,
    BCardTitle,
    BFormSelect,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [convertCase, toast],
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    if (!this.content) {
      return {}
    }

    const fields = [
      'id',
      'title',
      'text',
      'difficulty',
      'duration',
      'order',
      'recurrenceInDays',
      'instantDistribution',
      'type',
      'template',
      'buttons',
    ]

    const contentFields = Object.fromEntries(
      fields.map(field => {
        if (field === 'template' && !this.content[this.camelToSnake(field)]) {
          return [field, plannerContentTemplateOptions[0].value]
        }

        if (field === 'difficulty' && !this.content[this.camelToSnake(field)]) {
          return [field, plannerActionDifficultiesOptions[0].value]
        }

        if (field === 'type' && !this.content[this.camelToSnake(field)]) {
          return [field, '']
        }

        if (field === 'duration' && !this.content[this.camelToSnake(field)]) {
          return [field, 1]
        }

        if (field === 'order' && !this.content[this.camelToSnake(field)]) {
          return [field, 1]
        }

        if (field === 'recurrenceInDays' && !this.content[this.camelToSnake(field)]) {
          return [field, 0]
        }

        if (field === 'instantDistribution' && !this.content[this.camelToSnake(field)]) {
          return [field, 0]
        }

        if (field === 'buttons' && !this.content[this.camelToSnake(field)]) {
          return [field, []]
        }

        return [field, this.content[this.camelToSnake(field)]]
      }),
    )

    return {
      plannerActionDifficultiesOptions,
      plannerContentTemplateOptions,
      plannerContentButtonTargetOptions,
      plannerContentButtonTypeOptions,
      userTypeOptions: withEmptyOptionFirst([...userTypes, ...[{ value: 'all', text: 'Ambos' }]]),
      isStoring: false,
      fields,
      ...contentFields,
    }
  },
  computed: {
    contentForm() {
      const entries = this.fields.map(k => [this.camelToSnake(k), this[k]])
      return Object.fromEntries(entries)
    },
  },
  watch: {
    content: {
      handler(content) {
        if (!content) {
          this.$router.push({ name: 'cms-planner-contents-list' })
        }
      },
      immediate: true,
    },
  },
  methods: {
    async asyncPostStore() {
      return this.$refs.rulesContent.validate().then(success => {
        if (!success) {
          this.toastDanger('Erros de validação', 'Preencha todos os campos obrigatórios')
          return
        }

        this.isStoring = true

        try {
          PlannerService.content.store(this.contentForm).then(() => {
            this.$router.push({ name: 'cms-planner-contents-list' })
          })
        } catch (error) {
          const modal = {}
          if (error.response) {
            Object.assign(modal, {
              title: 'Erro ao salvar conteúdo',
              text: Object.values(error.response.data.errors),
              icon: 'error',
            })
          } else {
            Object.assign(modal, {
              title: 'Erro ao salvar conteúdo',
              text: error.message,
              icon: 'error',
            })
          }

          this.$swal({
            ...modal,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } finally {
          this.isStoring = false
        }
      })
    },

    async store() {
      if (this.instantDistribution) {
        this.$swal({
          title: 'Distribuir conteúdo para todos os usuários com planejador ativo?',
          text: 'Verifique se o conteúdo foi preenchido corretamente. Após a distribuição não será possível ajustar o texto/botões para os usuário que já receberam o conteúdo',
          icon: 'question',
          confirmButtonText: 'Está tudo certo, pode salvar e distribuir',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.asyncPostStore()
          }
        })
      } else {
        this.asyncPostStore()
      }
    },

    addButton() {
      this.buttons.push({
        link: '',
        text: '',
        type: plannerContentButtonTypeOptions[0].value,
        target: plannerContentButtonTargetOptions[0].value,
      })
    },
  },
}
</script>
